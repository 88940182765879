import '../style/Background.css'

export function GridBackground(){
    return (
      <div className="GridBackground">
      </div>
    )
}
  
export function ColoredBackground(){
    return (
      <div className="ColoredBackground">
      </div>
    )
}
 
